var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[(_vm.loader)?_c('v-row',{staticClass:"d-flex justify-center"},[_c('v-col',{staticClass:"d-flex justify-center align-center",attrs:{"cols":"12"}},[_c('h1',{staticClass:"text-center"},[_vm._v(_vm._s(_vm.$tc("Views.sh-1")))])]),_c('v-col',{attrs:{"cols":"12"}},[(_vm.loader)?_c('Loader',{attrs:{"message":_vm.$tc('Views.sh-2')}}):_vm._e()],1)],1):(_vm.systemSummary !== null)?_c('div',[_c('div',{staticClass:"d-flex justify-center pa-8"},[_c('v-img',{attrs:{"src":_vm.image,"max-width":"250","max-height":"150","contain":""}})],1),_c('h1',{staticClass:"text-center",staticStyle:{"padding-bottom":"1em"}},[_vm._v(" "+_vm._s(_vm.$tc("Views.sh-0"))+" "+_vm._s(_vm.userAlias || "user")+" ")]),_c('p'),_c('div',{directives:[{name:"can",rawName:"v-can",value:(['DASHBOARD']),expression:"['DASHBOARD']"}]},[_c('v-row',{staticClass:"mb-12"},[_c('v-col',[_c('v-card',{staticClass:"pa-3",attrs:{"elevation":"12"}},[_c('h3',[_vm._v(_vm._s(_vm.$tc("Views.sh-3")))]),_c('v-divider',{staticClass:"my-3"}),_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('pie-chart',{attrs:{"data":[
                    [_vm.$tc('Views.sh-4'), _vm.systemSummary.users.clients],
                    [_vm.$tc('Views.sh-5'), _vm.systemSummary.users.staff] ]}})],1),_c('v-col',{staticClass:"d-flex justify-center align-center",attrs:{"cols":"12","sm":"6"}},[_c('div',[_c('h2',[_vm._v(" "+_vm._s(_vm.$tc("Views.sh-4"))+" ("+_vm._s(_vm.$tc("Views.sh-6"))+": "+_vm._s(_vm.systemSummary.users.clients)+") ")]),_c('h2',[_vm._v(" "+_vm._s(_vm.$tc("Views.sh-5"))+" ("+_vm._s(_vm.$tc("Views.sh-6"))+": "+_vm._s(_vm.systemSummary.users.staff)+") ")])])])],1),_c('v-divider',{staticClass:"my-6"}),_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('pie-chart',{attrs:{"data":[
                    [
                      _vm.$tc('Views.sh-7'),
                      _vm.systemSummary.users.total_active_users ],
                    [
                      _vm.$tc('Views.sh-8'),
                      _vm.systemSummary.users.total_inactive_users ],
                    [
                      _vm.$tc('Views.sh-9'),
                      _vm.systemSummary.users.total_validating_users ],
                    [
                      _vm.$tc('Views.sh-10'),
                      _vm.systemSummary.users.total_deleted_users ] ]}})],1),_c('v-col',{staticClass:"d-flex justify-center align-center",attrs:{"cols":"12","sm":"6"}},[_c('div',[_c('h2',[_vm._v(_vm._s(_vm.$tc("Views.sh-11")))])])])],1)],1)],1)],1),_c('v-row',{staticClass:"mb-12"},[_c('v-col',[_c('v-card',{staticClass:"pa-3",attrs:{"raised":"","elevation":"12"}},[_c('h3',[_vm._v(" "+_vm._s(_vm.$tc("Views.sh-12"))+" ("+_vm._s(_vm.$tc("Views.sh-6"))+": "+_vm._s(_vm.systemSummary.investments.total)+") ")]),_c('v-divider',{staticClass:"my-3"}),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('column-chart',{attrs:{"data":[
                    [
                      _vm.$tc('Views.sh-13'),
                      _vm.systemSummary.investments.total_reserved_investments ],
                    [
                      _vm.$tc('Views.sh-14'),
                      _vm.systemSummary.investments
                        .total_waiting_for_users_investments ],
                    [
                      _vm.$tc('Views.sh-15'),
                      _vm.systemSummary.investments.total_to_do_investments ],
                    [
                      _vm.$tc('Views.sh-16'),
                      _vm.systemSummary.investments.total_in_process_investments ],
                    [
                      _vm.$tc('Views.sh-17'),
                      _vm.systemSummary.investments.total_validating_investments ],
                    [
                      _vm.$tc('Views.sh-18'),
                      _vm.systemSummary.investments.total_finished_investments ],
                    [
                      _vm.$tc('Views.sh-19'),
                      _vm.systemSummary.investments.total_canceled_investments ] ]}})],1)],1)],1)],1)],1),_c('v-row',{staticClass:"mb-12"},[_c('v-col',[_c('v-card',{staticClass:"pa-3",attrs:{"raised":"","elevation":"12"}},[_c('h3',[_vm._v(_vm._s(_vm.$tc("Views.sh-20")))]),_c('v-divider',{staticClass:"my-3"}),_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('pie-chart',{attrs:{"data":[
                    [
                      _vm.$tc('Views.sh-16'),
                      _vm.systemSummary.returns.total_in_process_returns ],
                    [
                      _vm.$tc('Views.sh-18'),
                      _vm.systemSummary.returns.total_finished_returns ] ]}})],1),_c('v-col',{staticClass:"d-flex justify-center align-center",attrs:{"cols":"12","sm":"6"}},[_c('div',[_c('h2',[_vm._v(" "+_vm._s(_vm.$tc("Views.sh-20"))+" ("+_vm._s(_vm.$tc("Views.sh-6"))+": "+_vm._s(_vm.systemSummary.returns.total)+") ")])])])],1)],1)],1)],1),_c('v-row',{staticClass:"mb-12"},[_c('v-col',[_c('v-card',{staticClass:"pa-3",attrs:{"raised":"","elevation":"12"}},[_c('h3',[_vm._v(_vm._s(_vm.$tc("Views.sh-21")))]),_c('v-divider',{staticClass:"my-3"}),_c('v-row',{staticClass:"my-6"},[_c('v-col',{attrs:{"cols":"12"}},[_c('h4',{staticClass:"mb-3"},[_vm._v(" "+_vm._s(_vm.$tc("Views.sh-22"))+" ("+_vm._s(_vm.$tc("Views.sh-6"))+": "+_vm._s(_vm.systemSummary.payments.traditional.total)+") ")]),_c('column-chart',{attrs:{"data":[
                    [
                      _vm.$tc('Views.sh-18'),
                      _vm.systemSummary.payments.traditional.total_finished ],
                    [
                      _vm.$tc('Views.sh-17'),
                      _vm.systemSummary.payments.traditional.total_validating ],
                    [
                      _vm.$tc('Views.sh-19'),
                      _vm.systemSummary.payments.traditional.total_canceled ] ]}})],1)],1),_c('v-divider',{staticClass:"my-3"}),_c('v-row',{staticClass:"my-6"},[_c('v-col',{attrs:{"cols":"12"}},[_c('h4',{staticClass:"mb-3"},[_vm._v(" "+_vm._s(_vm.$tc("Views.sh-23"))+" ("+_vm._s(_vm.$tc("Views.sh-6"))+": "+_vm._s(_vm.systemSummary.payments.crypto.total)+") ")]),_c('column-chart',{attrs:{"data":[
                    [
                      _vm.$tc('Views.sh-18'),
                      _vm.systemSummary.payments.crypto.total_finished ],
                    [
                      _vm.$tc('Views.sh-17'),
                      _vm.systemSummary.payments.crypto.total_validating ],
                    [
                      _vm.$tc('Views.sh-19'),
                      _vm.systemSummary.payments.crypto.total_canceled ] ]}})],1)],1),_c('v-divider',{staticClass:"my-3"}),_c('v-row',{staticClass:"my-6"},[_c('v-col',{attrs:{"cols":"12"}},[_c('h4',{staticClass:"mb-3"},[_vm._v(" "+_vm._s(_vm.$tc("Views.sh-24"))+" ("+_vm._s(_vm.$tc("Views.sh-6"))+": "+_vm._s(_vm.systemSummary.payments.balance.total)+") ")]),_c('column-chart',{attrs:{"data":[
                    [
                      _vm.$tc('Views.sh-49'),
                      _vm.systemSummary.payments.balance.total ] ]}})],1)],1)],1)],1)],1),_c('v-row',{staticClass:"mb-12"},[_c('v-col',[_c('v-card',{staticClass:"pa-3",attrs:{"raised":"","elevation":"12"}},[_c('h3',[_vm._v(_vm._s(_vm.$tc("Views.sh-25")))]),_c('v-divider',{staticClass:"my-3"}),_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('pie-chart',{attrs:{"data":[
                    [
                      _vm.$tc('Views.sh-7'),
                      _vm.systemSummary.product_comments
                        .total_active_product_comments ],
                    [
                      _vm.$tc('Views.sh-18'),
                      _vm.systemSummary.product_comments
                        .total_finished_product_comments ],
                    [
                      _vm.$tc('Views.sh-10'),
                      _vm.systemSummary.product_comments
                        .total_deleted_product_comments ] ]}})],1),_c('v-col',{staticClass:"d-flex justify-center align-center",attrs:{"cols":"12","sm":"6"}},[_c('div',[_c('h2',[_vm._v(" "+_vm._s(_vm.$tc("Views.sh-26"))+" ("+_vm._s(_vm.$tc("Views.sh-6"))+": "+_vm._s(_vm.systemSummary.product_comments.total)+") ")])])])],1),_c('v-divider',{staticClass:"my-6"}),_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('pie-chart',{attrs:{"data":[
                    [
                      _vm.$tc('Views.sh-27'),
                      _vm.systemSummary.bill_comments.total_sent_bill_comments ],
                    [
                      _vm.$tc('Views.sh-28'),
                      _vm.systemSummary.bill_comments.total_opened_bill_comments ] ]}})],1),_c('v-col',{staticClass:"d-flex justify-center align-center",attrs:{"cols":"12","sm":"6"}},[_c('div',[_c('h2',[_vm._v(" "+_vm._s(_vm.$tc("Views.sh-29"))+" ("+_vm._s(_vm.$tc("Views.sh-6"))+": "+_vm._s(_vm.systemSummary.bill_comments.total)+") ")])])])],1)],1)],1)],1),_c('v-row',{staticClass:"mb-12"},[_c('v-col',[_c('v-card',{staticClass:"pa-3",attrs:{"raised":"","elevation":"12"}},[_c('h3',[_vm._v(_vm._s(_vm.$tc("Views.sh-30")))]),_c('v-divider',{staticClass:"my-3"}),_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('pie-chart',{attrs:{"data":[
                    [
                      _vm.$tc('Views.sh-7'),
                      _vm.systemSummary.payment_platforms.total_active_platform ],
                    [
                      _vm.$tc('Views.sh-8'),
                      _vm.systemSummary.payment_platforms.total_inactive_platform ],
                    [
                      _vm.$tc('Views.sh-10'),
                      _vm.systemSummary.payment_platforms.total_deleted_platform ] ]}})],1),_c('v-col',{staticClass:"d-flex justify-center align-center",attrs:{"cols":"12","sm":"6"}},[_c('div',[_c('h2',[_vm._v(" "+_vm._s(_vm.$tc("Views.sh-31"))+" ("+_vm._s(_vm.$tc("Views.sh-6"))+": "+_vm._s(_vm.systemSummary.payment_platforms.total)+") ")]),_c('h2',[_vm._v(" "+_vm._s(_vm.$tc("Views.sh-32"))+" ("+_vm._s(_vm.$tc("Views.sh-6"))+": "+_vm._s(_vm.systemSummary.payment_platforms .total_integrated_platforms)+") ")]),_c('h2',[_vm._v(" "+_vm._s(_vm.$tc("Views.sh-33"))+" ("+_vm._s(_vm.$tc("Views.sh-6"))+": "+_vm._s(_vm.systemSummary.payment_platforms.total_custom_platforms)+") ")])])])],1)],1)],1)],1),_c('v-row',{staticClass:"mb-12"},[_c('v-col',[_c('v-card',{staticClass:"pa-3",attrs:{"raised":"","elevation":"12"}},[_c('h3',[_vm._v(_vm._s(_vm.$tc("Views.sh-34")))]),_c('v-divider',{staticClass:"my-3"}),_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('pie-chart',{attrs:{"data":[
                    [
                      _vm.$tc('Views.sh-7'),
                      _vm.systemSummary.products.total_active_products ],
                    [
                      _vm.$tc('Views.sh-8'),
                      _vm.systemSummary.products.total_inactive_products ],
                    [
                      _vm.$tc('Views.sh-10'),
                      _vm.systemSummary.products.total_deleted_products ] ]}})],1),_c('v-col',{staticClass:"d-flex justify-center align-center",attrs:{"cols":"12","sm":"6"}},[_c('div',[_c('h2',[_vm._v(" "+_vm._s(_vm.$tc("Views.sh-34"))+" ("+_vm._s(_vm.$tc("Views.sh-6"))+": "+_vm._s(_vm.systemSummary.products.total)+") ")])])])],1)],1)],1)],1),_c('v-row',{staticClass:"mb-12"},[_c('v-col',[_c('v-card',{staticClass:"pa-3",attrs:{"raised":"","elevation":"12"}},[_c('h3',[_vm._v(_vm._s(_vm.$tc("Views.sh-35")))]),_c('v-divider',{staticClass:"my-3"}),_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('pie-chart',{attrs:{"data":[
                    [
                      _vm.$tc('Views.sh-7'),
                      _vm.systemSummary.categories.total_active_categories ],
                    [
                      _vm.$tc('Views.sh-8'),
                      _vm.systemSummary.categories.total_inactive_categories ],
                    [
                      _vm.$tc('Views.sh-10'),
                      _vm.systemSummary.categories.total_deleted_categories ] ]}})],1),_c('v-col',{staticClass:"d-flex justify-center align-center",attrs:{"cols":"12","sm":"6"}},[_c('div',[_c('h2',[_vm._v(" "+_vm._s(_vm.$tc("Views.sh-35"))+" ("+_vm._s(_vm.$tc("Views.sh-6"))+": "+_vm._s(_vm.systemSummary.categories.total)+") ")])])])],1)],1)],1)],1),_c('v-row',{staticClass:"mb-12"},[_c('v-col',[_c('v-card',{staticClass:"pa-3",attrs:{"raised":"","elevation":"12"}},[_c('h3',[_vm._v(_vm._s(_vm.$tc("Views.sh-36")))]),_c('v-divider',{staticClass:"my-3"}),_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('pie-chart',{attrs:{"data":[
                    [
                      _vm.$tc('Views.sh-7'),
                      _vm.systemSummary.roles.total_active_roles ],
                    [
                      _vm.$tc('Views.sh-8'),
                      _vm.systemSummary.users.total_inactive_roles ],
                    [
                      _vm.$tc('Views.sh-10'),
                      _vm.systemSummary.users.total_deleted_roles ] ]}})],1),_c('v-col',{staticClass:"d-flex justify-center align-center",attrs:{"cols":"12","sm":"6"}},[_c('div',[_c('h2',[_vm._v(" "+_vm._s(_vm.$tc("Views.sh-37"))+" ("+_vm._s(_vm.$tc("Views.sh-6"))+": "+_vm._s(_vm.systemSummary.roles.total)+") ")]),_c('h2',[_vm._v(" "+_vm._s(_vm.$tc("Views.sh-38"))+" ("+_vm._s(_vm.$tc("Views.sh-6"))+": "+_vm._s(_vm.systemSummary.privileges.total)+") ")])])])],1)],1)],1)],1),_c('v-row',{staticClass:"mb-12"},[_c('v-col',[_c('v-card',{staticClass:"pa-3",attrs:{"raised":"","elevation":"12"}},[_c('h3',[_vm._v(_vm._s(_vm.$tc("Views.sh-39")))]),_c('v-divider',{staticClass:"my-3"}),_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('pie-chart',{attrs:{"data":[
                    [
                      _vm.$tc('Views.sh-7'),
                      _vm.systemSummary.terms.total_active_terms ],
                    [
                      _vm.$tc('Views.sh-8'),
                      _vm.systemSummary.terms.total_inactive_terms ],
                    [
                      _vm.$tc('Views.sh-10'),
                      _vm.systemSummary.terms.total_deleted_terms ] ]}})],1),_c('v-col',{staticClass:"d-flex justify-center align-center",attrs:{"cols":"12","sm":"6"}},[_c('div',[_c('h2',[_vm._v(" "+_vm._s(_vm.$tc("Views.sh-39"))+" ("+_vm._s(_vm.$tc("Views.sh-6"))+": "+_vm._s(_vm.systemSummary.terms.total)+") ")])])])],1)],1)],1)],1),_c('v-row',{staticClass:"mb-12"},[_c('v-col',[_c('v-card',{staticClass:"pa-3",attrs:{"raised":"","elevation":"12"}},[_c('h3',[_vm._v(_vm._s(_vm.$tc("Views.sh-40")))]),_c('v-divider',{staticClass:"my-3"}),_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('pie-chart',{attrs:{"data":[
                    [
                      _vm.$tc('Views.sh-7'),
                      _vm.systemSummary.details.total_active_details ],
                    [
                      _vm.$tc('Views.sh-8'),
                      _vm.systemSummary.details.total_inactive_details ],
                    [
                      _vm.$tc('Views.sh-10'),
                      _vm.systemSummary.details.total_deleted_details ] ]}})],1),_c('v-col',{staticClass:"d-flex justify-center align-center",attrs:{"cols":"12","sm":"6"}},[_c('div',[_c('h2',[_vm._v(" "+_vm._s(_vm.$tc("Views.sh-40"))+" ("+_vm._s(_vm.$tc("Views.sh-6"))+": "+_vm._s(_vm.systemSummary.details.total)+") ")])])])],1)],1)],1)],1),_c('v-row',{staticClass:"mb-12"},[_c('v-col',[_c('v-card',{staticClass:"pa-3",attrs:{"raised":"","elevation":"12"}},[_c('h3',[_vm._v(_vm._s(_vm.$tc("Views.sh-41")))]),_c('v-divider',{staticClass:"my-3"}),_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('pie-chart',{attrs:{"data":[
                    [
                      _vm.$tc('Views.sh-7'),
                      _vm.systemSummary.social_networks
                        .total_active_social_networks ],
                    [
                      _vm.$tc('Views.sh-8'),
                      _vm.systemSummary.social_networks
                        .total_inactive_social_networks ],
                    [
                      _vm.$tc('Views.sh-10'),
                      _vm.systemSummary.social_networks
                        .total_deleted_social_networks ] ]}})],1),_c('v-col',{staticClass:"d-flex justify-center align-center",attrs:{"cols":"12","sm":"6"}},[_c('div',[_c('h2',[_vm._v(" "+_vm._s(_vm.$tc("Views.sh-41"))+" ("+_vm._s(_vm.$tc("Views.sh-6"))+": "+_vm._s(_vm.systemSummary.social_networks.total)+") ")])])])],1)],1)],1)],1),_c('v-row',{staticClass:"mb-12"},[_c('v-col',[_c('v-card',{staticClass:"pa-3",attrs:{"raised":"","elevation":"12"}},[_c('h3',[_vm._v(_vm._s(_vm.$tc("Views.sh-42")))]),_c('v-divider',{staticClass:"my-3"}),_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('pie-chart',{attrs:{"data":[
                    [_vm.$tc('Views.sh-43'), _vm.systemSummary.files.total_kyc_files],
                    [_vm.$tc('Views.sh-44'), _vm.systemSummary.files.total_files] ]}})],1),_c('v-col',{staticClass:"d-flex justify-center align-center",attrs:{"cols":"12","sm":"6"}},[_c('div',[_c('h2',{staticClass:"mb-6"},[_vm._v(" "+_vm._s(_vm.$tc("Views.sh-42"))+" "),_c('br'),_vm._v(" ("+_vm._s(_vm.$tc("Views.sh-6"))+": "+_vm._s(_vm.systemSummary.files.total)+" / "+_vm._s(_vm._f("bytes")(_vm.systemSummary.files.total_bytes))+") ")]),_c('h2',{staticClass:"mb-6"},[_vm._v(" "+_vm._s(_vm.$tc("Views.sh-44"))+" "),_c('br'),_vm._v(" ("+_vm._s(_vm.$tc("Views.sh-6"))+": "+_vm._s(_vm.systemSummary.files.total_files)+" / "+_vm._s(_vm._f("bytes")(_vm.systemSummary.files.total_files_bytes))+" ) ")]),_c('h2',[_vm._v(" "+_vm._s(_vm.$tc("Views.sh-43"))+" "),_c('br'),_vm._v(" ("+_vm._s(_vm.$tc("Views.sh-6"))+": "+_vm._s(_vm.systemSummary.files.total_kyc_files)+" / "+_vm._s(_vm._f("bytes")(_vm.systemSummary.files.total_kyc_files_bytes))+") ")])])])],1)],1)],1)],1),_c('v-row',{staticClass:"mb-12"},[_c('v-col',[_c('v-card',{staticClass:"pa-3",attrs:{"raised":"","elevation":"12"}},[_c('h3',[_vm._v(_vm._s(_vm.$tc("Views.sh-45")))]),_c('v-divider',{staticClass:"my-3"}),_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('pie-chart',{attrs:{"data":[
                    [
                      _vm.$tc('Views.sh-46'),
                      _vm.systemSummary.newsletter.subscribers ],
                    [_vm.$tc('Views.sh-47'), _vm.systemSummary.feedbacks.total],
                    [_vm.$tc('Views.sh-48'), _vm.systemSummary.faqs.total] ]}})],1),_c('v-col',{staticClass:"d-flex justify-center align-center",attrs:{"cols":"12","sm":"6"}},[_c('div',[_c('h2',[_vm._v(" "+_vm._s(_vm.$tc("Views.sh-46"))+" ("+_vm._s(_vm.$tc("Views.sh-6"))+": "+_vm._s(_vm.systemSummary.newsletter.subscribers)+") ")]),_c('h2',[_vm._v(" "+_vm._s(_vm.$tc("Views.sh-47"))+" ("+_vm._s(_vm.$tc("Views.sh-6"))+": "+_vm._s(_vm.systemSummary.feedbacks.total)+") ")]),_c('h2',[_vm._v(" "+_vm._s(_vm.$tc("Views.sh-48"))+" ("+_vm._s(_vm.$tc("Views.sh-6"))+": "+_vm._s(_vm.systemSummary.faqs.total)+") ")])])])],1)],1)],1)],1)],1)]):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }